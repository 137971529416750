
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/en-ca'
import 'dayjs/locale/de'
import 'dayjs/locale/fr'
import 'dayjs/locale/es'
import 'dayjs/locale/pt'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/ru'
import 'dayjs/locale/tr'
import 'dayjs/locale/hi'
import 'dayjs/locale/fa'
import 'dayjs/locale/uz'
import 'dayjs/locale/kk'
import 'dayjs/locale/es-mx'
import 'dayjs/locale/it'
import 'dayjs/locale/et'
import 'dayjs/locale/fi'
import 'dayjs/locale/el'
import 'dayjs/locale/id'
import 'dayjs/locale/nb'
import 'dayjs/locale/pl'
import 'dayjs/locale/ro'
import 'dayjs/locale/se'
import 'dayjs/locale/cs'
import 'dayjs/locale/da'
import 'dayjs/locale/nl'
import localeData from 'dayjs/plugin/localeData'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'


dayjs.extend(updateLocale)

dayjs.extend(localeData)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(relativeTime)
dayjs.extend(utc)



// defaultLocale: "en"


dayjs.updateLocale("en")
dayjs.locale('en')


export default dayjs
